import React, { useState } from 'react';
import logo from './assets/fsc.logo.png';
import lowesLogo from './assets/lowes-logo.webp';
import footerLogo from './assets/fsc-footlogo.png';
import facebook from './assets/facebook.png';
import instagram from './assets/instagram.png';
import twitter from './assets/twitter.png';
import youtube from './assets/youtube.png';
import linkedin from './assets/linked-in.png';
import './App.scss';
import HamburgerMenu from 'react-hamburger-menu';
import config from './config';

function App() {
    // console.log("beginning to lay out page");
    const [showSubmenu, setShowSubmenu] = useState({
        sourcing: false,
        resources: false,
    });
    const [burgerOpen, setBurgerOpen] = useState(false);

    const handleBurgerClick = () => {
        setBurgerOpen(!burgerOpen);
    };
    let woodGroupTimer;

    return (
        <div
            className={burgerOpen ? 'App hideStuff' : 'App'}
            onClick={() => {
                setShowSubmenu({
                    ...showSubmenu,
                    resources: false,
                    sourcing: false,
                });
            }}
        >
            <header>
                <div id='hamburgerHelper'>
                    <div id='hamburgerWrapper'>
                        <HamburgerMenu
                            isOpen={burgerOpen}
                            menuClicked={handleBurgerClick}
                            width={20}
                            height={20}
                            strokeWidth={3}
                            rotate={0}
                            color='#285c4d'
                            borderRadius={0}
                            animationDuration={0.25}
                        />
                    </div>
                </div>
                <div id='pinStripe'></div>
                <a href='https://buildwithfsc.org/'>
                    <img
                        src={logo}
                        className='mobileHeaderLogo'
                        alt='FSC-US Logo -- Forests For All Forever'
                    />
                </a>

                <div className='outerMenuRow'>
                    <div className='menuContainer'>
                        <a href='https://buildwithfsc.org/'>
                            <img
                                src={logo}
                                className='headerLogo'
                                alt='FSC-US Logo -- Forests For All Forever'
                            />
                        </a>

                        <ul className='menu'>
                            <li>
                                <a href='https://buildwithfsc.org/'>HOME</a>
                            </li>
                            <li>
                                <a href='https://buildwithfsc.org/why-fsc'>
                                    WHY FSC
                                </a>
                            </li>
                            <li>
                                <a href={`${config.frontendRoot}`}>SOURCING</a>
                            </li>
                            <li>
                                <a href='https://buildwithfsc.org/benefits-for-pros'>
                                    BENEFITS FOR PROS
                                </a>
                            </li>
                            <li
                                onMouseLeave={() => {
                                    setTimeout(function () {
                                        woodGroupTimer = setShowSubmenu({
                                            ...showSubmenu,
                                            resources: false,
                                            sourcing: false,
                                        });
                                    }, 750);
                                    // console.log("set woodgroup timer");
                                }}
                                onMouseOver={() => {
                                    clearTimeout(woodGroupTimer);
                                    if (
                                        showSubmenu.sourcing ||
                                        !showSubmenu.resources
                                    ) {
                                        setShowSubmenu({
                                            sourcing: false,
                                            resources: true,
                                        });
                                        // console.log("clear woodgroup timer");
                                    }
                                }}
                            >
                                <a href='https://buildwithfsc.org/resources'>
                                    RESOURCES
                                </a>

                                {showSubmenu.resources && (
                                    <ul>
                                        <li>
                                            <a href='https://buildwithfsc.org/blog'>
                                                BLOG
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://buildwithfsc.org/continuing-education'>
                                                CONTINUING EDUCATION
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://buildwithfsc.org/pro-tips'>
                                                PRO TIPS
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://buildwithfsc.org/climate-smart-wood-group'>
                                                CLIMATE-SMART CONSTRUCTION GROUP
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://buildwithfsc.org/success-stories'>
                                                SUCCESS STORIES
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <div id='main'>
                <iframe
                    id='fsc-search-iframe'
                    data-affiliate='fsc-us'
                    title='FSC-US Buyer Portal'
                    src='https://'
                ></iframe>
            </div>
            <footer>
                <div className='footerSocialContainer'>
                    <a href='https://www.linkedin.com/company/forest-stewardship-council-u.s./'>
                        <img
                            src={linkedin}
                            className='socialLinks'
                            alt='Linked In'
                        />
                    </a>
                    <a href='https://www.facebook.com/pages/Forest-Stewardship-Council-FSC/88211771707'>
                        <img
                            src={facebook}
                            className='socialLinks'
                            alt='Facebook'
                        />
                    </a>
                    <a href='https://twitter.com/#!/FSC_IC'>
                        <img
                            src={twitter}
                            className='socialLinks'
                            alt='Twitter'
                        />
                    </a>
                    <a href='https://www.youtube.com/user/fscinternational'>
                        <img
                            src={youtube}
                            className='socialLinks'
                            alt='YouTube'
                        />
                    </a>
                    <a href='https://instagram.com/fsc_international'>
                        <img
                            src={instagram}
                            className='socialLinks'
                            alt='Instagram'
                        />
                    </a>
                </div>
                <p>&nbsp;</p>
                <div className='footerImageContainer'>
                    <img
                        src={footerLogo}
                        className='footerLogo'
                        alt='FSC-US Logo'
                    />
                </div>
                <p>
                    Copyright © 2021 Forest Stewardship Council US&nbsp;&nbsp;{' '}
                    <a href='https://us.fsc.org/en-us/legal'>Legal</a>
                </p>
                ​<p>&nbsp;</p>
                <p className='sponsorP'>Thanks to our 2022 sponsor:</p>
                <p>&nbsp;</p>
                <p>
                    <a href='https://corporate.lowes.com/our-responsibilities/product-sustainability-value-chain'>
                        <img
                            src={lowesLogo}
                            alt='Lowes Logo'
                            className='sponsorLogo'
                        ></img>
                    </a>
                </p>
                <p className='endVspace'>&nbsp;</p>
            </footer>
            {burgerOpen && (
                <div className='mobileMenuOverlay'>
                    <ul>
                        <li>
                            <a href='https://buildwithfsc.org'>HOME</a>
                        </li>
                        <div className='separator'></div>
                        <li>
                            <a href='https://buildwithfsc.org/why-fsc'>
                                WHY FSC
                            </a>
                        </li>
                        <div className='separator'></div>
                        <li>
                            <a
                                href={`${config.frontendRoot}`}
                                className='current'
                            >
                                SOURCING
                            </a>
                        </li>
                        <div className='separator'></div>
                        <li>
                            <a href='https://buildwithfsc.org/benefits-for-pros'>
                                BENEFITS FOR PROS
                            </a>
                        </li>
                        <div className='separator'></div>
                        <li className='subMenu'>
                            <a href='https://buildwithfsc.org/resources'>
                                +&nbsp;&nbsp;RESOURCES
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default App;
